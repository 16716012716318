/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Header from "./header"
import "./layout.css"
import Chat from "../images/chat.png"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <AnchorLink href="#contact" className="fixed z-30 bottom-4 right-4 ">
          <img className="w-20" src={Chat} alt="Contact us"></img>
        </AnchorLink>
        <footer className="px-4 py-8 lg:p-8 bg-gray-800 text-white text-sm">
          © {new Date().getFullYear()} Bluemans Engineering Ltd
          <br />
          <a href="tel:+64225456613">P: 022 545 6613</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
