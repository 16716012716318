import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Headroom from "react-headroom"

const Header = () => (
  <div className="fixed top-0 left-0 z-30 w-full">
    <Headroom>
      <div className="z-10 flex flex-wrap justify-between w-full p-4 lg:p-8">
        <h1>
          <Link to="/" className="font-semibold text-xl lg:text-2xl">
            Bluemans Engineering Ltd
          </Link>
        </h1>
        <ul className="hidden lg:flex lg:text-lg">
          <li className="ml-6">
            <AnchorLink href="#about">About Us</AnchorLink>
          </li>
          <li className="ml-6">
            <AnchorLink href="#services">Services</AnchorLink>
          </li>
          <li className="ml-6">
            <AnchorLink href="#contact">Contact</AnchorLink>
          </li>
        </ul>
      </div>
    </Headroom>
  </div>
)

export default Header
